<template>
  <!-- 会员中心 -->
  <div class="user-info">
    <!-- <div class="info">
      <div class="info-top">
        <div class="photo">
          <img :src="imgSrc" alt="">
        </div>
        <div class="detail">
          <span>
            <p style="font-size: 26px;">{{userInfo.realName ? userInfo.realName : userInfo.userName }}</p>
            <p style="cursor:pointer;" @click="goModify">修改个人资料</p>
          </span>
          <span>是否实名备案</span>
          <span>未读信息</span>
        </div>
      </div>
      <div class="info-bottom">
        <span v-if="userInfo.authSta == 1">已完成企业实名认证。</span>
        <span v-if="userInfo.authSta == 2">已完成个人实名认证。</span>
        <span v-if="userInfo.authSta == 3 || userInfo.authSta == 5">实名认证正在审核，请等待。</span>
        <span v-if="userInfo.authSta == 4 || userInfo.authSta == 6 || userInfo.authSta == 7">未申请实名认证或者实名认证申请失败。<br />请先进行<span style="color: #00a0e9;cursor:pointer;" @click="goCertificate">实名认证</span>，以确保后续交易正常进行。</span>
      </div>
    </div> -->
    <div class="project">
      <div class="s-title">
        <span class="title">项目查询</span>
        <span class="more" @click="handleMore">更多 >></span>
      </div>
      <div class="table-con">
        <!-- <el-form class="" :model="form" label-position="left" inline ref="form">
          <el-form-item label="地区">
            <el-cascader
              style="width: 78px;"
              v-model="form.region"
              :props="props"
              :options="regionOptions"
              :show-all-levels="false"
              @change="handelSelectChange"></el-cascader>
          </el-form-item>
          <el-form-item label="产权类别">
            <el-select style="width: 78px;" v-model="form.type" @change="handelSelectChange">
              <el-option v-for="item in typeOptions" :key="item.lx" :label="item.lx" :value="item.bh"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select style="width: 78px;" v-model="form.status" @change="handelSelectChange">
              <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="交易方式">
            <el-select style="width: 78px;" v-model="form.changeType">
              <el-option v-for="item in changeTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="面积">
            <el-select style="width: 78px;" v-model="form.area" @change="handelAreaChange">
              <el-option v-for="item in areaOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input style="width: 140px;" v-model="form.code" placeholder="项目编号"  @blur="handleBlur">
              <i slot="suffix" class="el-input__icon">
                <img src="imgs/icon-search2.png" alt="">
              </i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input style="width: 140px;" v-model="form.name" placeholder="项目名称"  @blur="handleBlur">
              <i slot="suffix" class="el-input__icon">
                <img src="imgs/icon-search2.png" alt="">
              </i>
            </el-input>
          </el-form-item>
        </el-form> -->
        <el-table
            border
            class="table"
            :data="tableData"
            style="width: 100%">
            <el-table-column align="center" prop="xmbh" label="项目编号" width="240px"></el-table-column>
            <el-table-column align="center" prop="xmmc" label="项目名称" width="130px"></el-table-column>
            <el-table-column align="center" prop="xmdz" label="项目地址"></el-table-column>
            <el-table-column align="center" prop="bmkssj" label="报名开始时间" width="160px"></el-table-column>
            <el-table-column align="center" prop="bmjzsj" label="报名截止时间" width="160px"></el-table-column>
            <el-table-column align="center" prop="xmzt" label="报名状态">
              <template v-slot="scope">
                <span :style="{'color': scope.row.xmzt == '正在报名' ? '#0bcb0a' : scope.row.xmzt == '报名结束' ? '#333333' : '#dc2929'}">
                  {{scope.row.xmzt}}
                </span>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagenation">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :current-page="currentPage"
              :page-size="pageSize"
              :total="total"
              @current-change="currentChange">
            </el-pagination>
          </div>
      </div>
    </div>
    <!-- <div class="news">
      <div class="s-title">
        <span class="title">我的订阅</span>
        <span class="more" @click="handleMore2">更多 >></span>
      </div>
      <div class="table-con">
        <el-table
            border
            class="table"
            :data="subscriptionTableData"
            style="width: 100%"
            :row-class-name="handleRowClass">
            <el-table-column align="center" prop="code" label="项目编号" width="120px"></el-table-column>
            <el-table-column align="center" prop="name" label="项目名称" width="260px"></el-table-column>
            <el-table-column align="center" prop="area" label="登记地点"></el-table-column>
            <el-table-column align="center" prop="date" label="公告日期"></el-table-column>
            <el-table-column align="center" prop="endTime" label="报名截止时间"></el-table-column>
            <el-table-column align="center" prop="type" label="交易方式"></el-table-column>
          </el-table>
      </div>
    </div> -->

  </div>
</template>

<script>
import { getPropertyType } from '@/api/exchange'
import { getProjectInfo, getRegionList } from '@/api/projectManage'
import { getAvatar } from '@/api/loginAndRegister'
import { getMemberInfo } from '@/api/account'
import { mapGetters } from 'vuex'
import { split } from '@/js/utils/util'
export default {
  data() {
    return {
      form: {region: '', type: '', status: '全部', area: ''},
      imgSrc: '',
      tableData: [],
      subscriptionTableData: [],
      regionOptions: [
        // {label: '游仙区', value: '420100'}
      ],
      props: {
        label: 'xzqmc',
        children: 'list',
        value: 'xzqbh',
        checkStrictly: true,
        emitPath: false
      },
      areaOptions: [
        {label: '全部', value: ''},
        {label: '100亩以下', value: 0, value2: 100},
        {label: '100亩-300亩', value: 100, value2: 300},
        {label: '300亩-500亩', value: 300, value2: 500},
        {label: '500亩-1000亩', value: 500, value2: 1000},
        {label: '1000亩以上', value: 1000, value2: ''},
      ],
      typeOptions: [
        {label: '全部', value: ''},
      ],
      statusOptions: [
        {label: '全部', value: '全部'}, {label: '未开始', value: '未开始'}, {label: '进行中', value: '进行中'}, {label: '已结束', value: '已结束'},
      ],
      changeTypeOptions: [
        {label: '全部', value: ''},
      ],
      currentPage: 1,
      pageSize: 5,
      total: 0,
    }
  },
  computed: mapGetters(['userInfo',]),
  created() {
    this.getRegions();
    this.initPropertyType();
    this.initData();
  },
  mounted() {

  },
  methods: {
    async getRegions() {
      // 获取信息
      let res = await getRegionList();
      let { status,data } = res;
      if(status) {
        // this.regionOptions = data;
        let temp = data;
        this.regionOptions = split(temp);
      }
    },
    handleRowClass({row, rowIndex}) {
      // 行樣式
      if(rowIndex % 2 === 0) {
        return  'odd'
      }
    },
    // async initAvatar() {
    //   // 获取用户头像
    //   console.log(this.userInfo.userName, 'userName')
    //   let res = await getAvatar(this.userInfo.userName);
    //   let {code, data} = res;
    //   if(code == 0) {
    //     this.imgSrc = data;
    //   }
    // },
    async initData() {
      // 获取数据
      let params = {
        xzqbh: this.form.region,
        cqlxbh: this.form.type,
        xmzt: this.form.status,
        zxmj: this.form.area,
        zdmj: this.form.maxArea,
        xmbh: this.form.code,
        keyword: this.form.name,
        pnum: this.currentPage,
        psize: this.pageSize
      }
      let res = await getProjectInfo(params);
      let {status, data, dataNum} = res;
      if(status) {
        this.tableData = data;
        this.total = dataNum;
      }
    },
    async initPropertyType() {
      // 初始化产权类型
      let res = await getPropertyType();
      let {status, data} = res;
      if(status) {
        this.typeOptions = data;
      }
    },
    handelSelectChange(val) {
      // 选择发生变化
      console.log(val, this.form.region, 'val')
      this.initData()
    },
    handelAreaChange(val) {
      // 面积发生变化
      let obj = this.areaOptions.filter(item => item.value === val)[0];
      this.form.maxArea = obj.value2;
      this.initData();
    },
    handleBlur(val) {
      // 输入框失去焦点时发生变化
      this.initData()
    },
    goCertificate() {
      // 实名认证
      this.$router.push({name: 'smrz'})
    },
    goModify() {
      // 修改资料
      this.$router.push({name: 'xgzl'});
    },
    currentChange(val) {
      this.currentPage = val;
      this.initData();
    },
    handleMore() {
      this.$router.push({name: 'zyzclzts'})
    },
    handleMore2() {
      this.$router.push({name: 'mySubscription'})
    }
  }
}
</script>

<style lang="less" scoped>
.user-info {
  text-align: left;
  height: 100%;
  position: relative;
  background: #fff;
  .info {
    width: 541px;
    margin: 0 auto;
    margin-top: 40px;
    .info-top {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      .photo {
        width: 104px;
        height: 103px;
        background-color: #e3e3e3;
        border-radius: 52px;
        img {
          border-radius: 52px;
          width: 100%;
          height: 100%;
        }
      }
      .detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 123px);
        text-align: left;
        line-height: 38px;
        margin-left: 11px;
        p {
          margin: 0;
        }
      }
    }
    .info-bottom {
      font-size: 20px;
      margin-top: 39px;
      text-align: center;
    }
  }

  .s-title {
    .title {
      margin-left: 10px;
      font-size: 15px;
      font-weight: 600;
    }
    .more {
      right: 30px;
    }
  }
  .project {
    padding-top: 30px;
  }
  .table-con {
    padding: 0 15px 0 18px;
  }
  .news {
    margin-top: 33px;
    position: relative;
  }
  .pagenation {
    // margin-top: 0;
    // position: absolute;
    // right: 20px;
    // bottom: 33px;
    text-align: right;
  }
}
</style>

<style lang="less">

</style>
